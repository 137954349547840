.fv_business_intro_container {
    background: #ffffffe6;
    border-radius: 15px;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



/********************************************************************************
 * Desktop Comparison Section
 ********************************************************************************/
.fv_business_intro_desktop_comparison {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-width: 1000px;
    width: 90%;
    margin: 2rem auto;
    padding: 0rem;
    font-size: 0.95rem;
    color: #1a202c;
}

.fv_business_intro_desktop_row_header {
    background-color: #f5f5f5;
    font-weight: 600;
    font-size: 1.25rem;
    border-radius: 15px 15px 0 0;
    padding: 1rem 0rem;
}

.fv_business_intro_row,
.fv_business_intro_row_common {
    font-size: 0.95rem;
    border-bottom: 1px solid #e5e5e5;
}

.fv_business_intro_row.last {
    border-bottom: 0px solid #e5e5e5;
}

.fv_business_intro_col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    padding-left: 1rem !important;
}

.fv_business_intro_col.right {
    border-left: 1px solid #e5e5e5;
    padding-left: 1rem !important;
}

/********************************************************************************
 * Mobile Layout
 ********************************************************************************/
.fv_business_intro_mobile_layout {
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
    position: relative;
}

/* Mobile card style (similar to .fv_indi-container) */
.fv_business_intro_mobile_card {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2rem;
    margin-bottom: 1.5rem;
}

/* Card title in mobile */
.fv_business_intro_card_title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #1a202c;
    background-color: #f5f5f5;
    font-weight: 600;
    padding: 1rem;
}

/* Mobile item text */
.fv_business_intro_mobile_item {
    border-bottom: 1px solid #ccc;
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.75rem;
    background-color: #fff;
    padding: 10px;
}

/********************************************************************************
 * Example Additional Components
 ********************************************************************************/
.fv_start_screen-button {
    background-color: #3a8dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;
}

.fv_start_screen-button:hover {
    background-color: #1c6fd9;
    transform: translateY(-2px);
}

.box-tab {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(198, 243, 255, 0.331);
}

.address-box-tab {
    padding: 10px 0px;
    background-color: #fff;
    margin: 10px 0;
    border-top: 1px solid #ddd;
}

.bold-text {
    font-weight: bold;
}

.fvi_text_area {
    background-color: #F1FAFC;
    border-radius: 6px;
    border: none;
}

.fvi_text_area:hover,
.fvi_text_area:focus {
    background-color: #F1FAFC;
}

.fvi_text_area:disabled {
    background-color: #F1FAFC;
    color: rgb(69, 69, 69);
}

.fv_prime_upgrade_container {
    background: #000;
    padding: 30px 20px;
    border-radius: 12px;
    margin-bottom: 30px;
    position: relative;
}

.fv_prime_upgrade_container:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 50px;
    left: 0;
    right: 0;
    background: url(./city.png) top center no-repeat;
    background-size: cover;
}

.fv_prime_upgrade_main_title {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.fv_prime_upgrade_lead {
    font-size: 18px;
    color: #AAA;
    max-width: 800px;
    margin: 0 auto;
}

.fv_prime_upgrade_lead-small {
    font-size: 16px;
    color: #AAA;
    max-width: 800px;
    margin: 0 auto;
}

.fv_prime_upgrade_subtitle {
    font-size: 24px;
    color: #2a3042;
    font-weight: 600;
    position: relative;
    padding-bottom: 1rem;
}

.fv_prime_upgrade_subtitle::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: #3b7ddd;
}

.fv_prime_upgrade_business_list {
    list-style: none;
    padding-left: 0;
}

.fv_prime_upgrade_business_item {
    font-size: 18px;
    padding: 12px 10px;
    margin: 8px 0;
    background: rgba(59, 125, 221, 0.05);
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.fv_prime_upgrade_business_item:hover {
    transform: translateX(10px);
}

.fv_prime_upgrade_brochure_link {
    transition: all 0.3s ease;
    border-width: 2px;
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #fff !important;
    background: none !important;
    color: #fff !important;
    font-size: 16px !important;
}

.fv_prime_upgrade_brochure_link.prime {
    transition: all 0.3s ease;
    border-width: 2px;
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #fff !important;
    background: none !important;
    color: #fff !important;
    font-size: 16px !important;
}

.fv_prime_upgrade_brochure_link:hover {
    background: #fff !important;
    color: #000 !important;
    transform: translateY(-2px);
}

.fv_prime_upgrade_contact_section {
    background: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.fv_prime_upgrade_email_link {
    color: #fff;
    text-decoration: underline !important;
    transition: all 0.2s ease;
}

.fv_prime_upgrade_email_link:hover {
    color: #2a5da3;
    text-decoration: underline;
}

.fv_prime_upgrade_cta {
    padding: 1rem 2.5rem;
    font-size: 20px !important;
    border-radius: 50px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(188, 188, 188, 0.3);
}

.fv_prime_upgrade_cta:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(59, 125, 221, 0.4);
}

@media (max-width: 768px) {
    .fv_prime_upgrade_main_title {
        font-size: 2rem;
    }

    .fv_prime_upgrade_subtitle {
        font-size: 1.75rem;
    }

    .fv_prime_upgrade_business_item {
        font-size: 1rem;
    }
}


// ===== PLUS PRIME COMPARISON PAGE =====

// styles.scss
.fv_plus_prime_container {
    width: 100%;
    max-width: 1200px;
    max-height: 60vh;
    margin: 0 auto 30px 0px;
    padding: 0rem;
    overflow: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;

    &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 3px;
    }
}

.fv_plus_prime_table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    min-width: 800px;

    th,
    td {
        transition: all 0.2s ease;
    }
}

.fv_plus_prime_th {
    padding: 1rem;
    text-align: left;
    vertical-align: top;
    background: linear-gradient(160deg, #f1f3f5  0%, #f8f9fa 100%);
    border-bottom: 3px solid #e9ecef;

    &:first-child {
        border-radius: 12px 0 0 0;
    }

  
}

.fv_plus_prime_plan_title {
    font-size: 1.1rem;
    font-weight: 700;
    color: #2b2d42;
    margin-bottom: 0.75rem;
}

.fv_plus_prime_plan_subtitle {
    font-size: 0.9rem;
    color: #6c757d;
    line-height: 1.5;
    font-weight: 400;
}


.fv_plus_prime_table td {
    padding: 1rem;
    border-bottom: 1px solid #edf2f4;
    position: relative;
    font-size: 14px;

    &:first-child {
        font-weight: 500;
        color: #2b2d42;
    }

}

.fv_plus_prime_check {
    color: #2a9d8f;
    font-size: 1.4rem;
    filter: drop-shadow(0 2px 4px rgba(42, 157, 143, 0.2));
}

.fv_plus_prime_wrong {
    color: #ca3131;
    font-size: 1.4rem;
    filter: drop-shadow(0 2px 4px rgba(42, 157, 143, 0.2));
}

// Hover effects
.fv_plus_prime_table tbody tr {
    &:hover {
        background: #fafafa;
    }
}

// Responsive design
@media (max-width: 768px) {
    .fv_plus_prime_container {
        margin: 1rem auto;
        padding: 0.5rem;
        box-shadow: none;
    }

    .fv_plus_prime_th {
        padding: 1rem;
    }

    .fv_plus_prime_table td {
        padding: 1rem;
        font-size: 0.95rem;
    }

    .fv_plus_prime_check {
        font-size: 1.2rem;
    }

    .fv_plus_prime_wrong {
        font-size: 1.2rem;
    }

}

@media (max-width: 480px) {
    .fv_plus_prime_plan_subtitle {
        br {
            display: none;
        }
    }

    .fv_plus_prime_table td {
        font-size: 0.9rem;
    }
}