.signPad {
    padding: 20px;
    border: none;
    width: 360px !important;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
}

.inner-sign-pad {
    height: 200px;
    width: 340px;
    border: 1px solid #ddd;
    background-color: #FFF;
}



/* Optional: Adjust responsiveness on smaller screens */
@media (max-width: 500px) {
    .inner-sign-pad {
        width: 100%;
        height: auto;
    }
}

.fv_application_document-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 5px 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;


    &+.fv_application_document-group {
        margin-top: 30px;
    }
}


/* /// BusinessPlusKycStatus Table /// */

/* Example minimal styling, adapt to your project's needs */
.fv_bplus_operators_wrapper {
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 1200px;
}

/* Title */
.fv_bplus_operators_title {
    border-top: 1px solid #ebebeb;
    padding-top: 15px;
    font-size: 1.25rem;
    font-weight: 600;
}

/* Loader / No data text */
.fv_bplus_operators_loader {
    min-height: 200px;
}

.fv_bplus_operators_noData {
    text-align: center;
    color: #888;
}

/* Table */
.fv_bplus_operators_table {
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
}

.fv_bplus_operators_table_head {
    background-color: #fafafa;
}

.fv_bplus_operators_table_th {
    font-weight: 600;
    white-space: nowrap;
}

.fv_bplus_operators_table_td {
    vertical-align: middle;
}

.fv_bplus_operators_table_tr:hover {
    background-color: #fdfdfd;
}

/* Link & copy icon */
.fv_bplus_operators_link {
    text-decoration: none;
    color: #007bff;
}

.fv_bplus_operators_link:hover {
    text-decoration: underline;
}

.fv_bplus_operators_copyIcon {
    color: #555;
}

.fv_bplus_operators_copyIcon:hover {
    color: #000;
}



/* Journey container */
.fv_application_journey_container {
    padding: 1rem;
    margin: 1rem 0 0 0;
}

.fv_application_journey_steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    position: relative;
}

/* Step general styling */
.fv_application_journey_step {
    position: relative;
    z-index: 1;
    text-align: center;
}

.fv_application_journey_step_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    transition: all 0.3s ease;
    border: 3px solid transparent;
}

.fv_application_journey_step_number {
    font-size: 1.2rem;
    font-weight: 600;
    color: #6c757d;
}

/* Completed state */
.fv_application_journey_step--completed .fv_application_journey_step_circle {
    background: #28a745;
    border-color: #1f7e34;
}

.fv_application_journey_step--completed .fv_application_journey_step_icon {
    color: white;
    font-size: 1.2rem;
}

/* Current state */
.fv_application_journey_step--current .fv_application_journey_step_circle {
    background: #3b7ddd;
    border-color: #2a5da3;
}

.fv_application_journey_step--current .fv_application_journey_step_number {
    color: white;
}

/* Content styling */
.fv_application_journey_content {
    padding: 0 1rem;
}

.fv_application_journey_title {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #2a3042;
}

.fv_application_journey_desc {
    font-size: 0.9rem;
    color: #6c757d;
    line-height: 1.4;
}

.fv_application_journey_link {
    color: #3b7ddd;
    text-decoration: none;
    transition: all 0.2s ease;
}

.fv_application_journey_link:hover {
    text-decoration: underline;
}

/* Connector lines */
.fv_application_journey_steps::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 3px;
    background: #e9ecef;
    z-index: 0;
}

/* Responsive design */
@media (max-width: 1000px) {
    .fv_application_journey_steps {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .fv_application_journey_step {
        display: flex;
        align-items: start;
        text-align: left;
    }

    .fv_application_journey_step_circle {
        margin: 0 1rem 0 0;
        flex-shrink: 0;
    }

    .fv_application_journey_steps::before {
        top: 0;
        left: 20px;
        width: 3px;
        height: 100%;
    }
}